/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'calendar3-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M0 2a2 2 0 012-2h12a2 2 0 012 2zm0 1v11a2 2 0 002 2h12a2 2 0 002-2V3z"/>',
    },
});
